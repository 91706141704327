@background_color_1: white;
@background_color_2: black;
@background_color_3: #2C67B1;

@-webkit-keyframes indeterminate {
	0% {
		left: -35%;
		right: 100%;
	}
	60% {
		left: 100%;
		right: -90%;
	}
	100% {
		left: 100%;
		right: -90%;
	}
}
@keyframes indeterminate {
	0% {
		left: -35%;
		right: 100%;
	}
	60% {
		left: 100%;
		right: -90%;
	}
	100% {
		left: 100%;
		right: -90%;
	}
}
@-webkit-keyframes indeterminate-short {
	0% {
		left: -200%;
		right: 100%;
	}
	60% {
		left: 107%;
		right: -8%;
	}
	100% {
		left: 107%;
		right: -8%;
	}
}
@keyframes indeterminate-short {
	0% {
		left: -200%;
		right: 100%;
	}
	60% {
		left: 107%;
		right: -8%;
	}
	100% {
		left: 107%;
		right: -8%;
	}
}
.progress {
	position: relative;
	height: 2px;
	display: block;
	width: 100%;
	background-color: @background_color_1;
	border-radius: 2px;
	background-clip: padding-box;
	overflow: hidden;
	.indeterminate {
		background-color: @background_color_2;
		&:before {
			content: '';
			position: absolute;
			background-color: @background_color_3;
			top: 0;
			left: 0;
			bottom: 0;
			will-change: left, right;
			-webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
			animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
		}
		&:after {
			content: '';
			position: absolute;
			background-color: @background_color_3;
			top: 0;
			left: 0;
			bottom: 0;
			will-change: left, right;
			-webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
			animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
			-webkit-animation-delay: 1.15s;
			animation-delay: 1.15s;
		}
	}
}
