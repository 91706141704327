@color_1: #414141;
@color_2: #00bcd4;
@color_3: #9b9b9b;
@color_4: #fff;
@color_5: #a1a1a1;
@color_6: #f8f8f8;
@color_7: #aaaaaa;
@color_8: #ffffff;
@color_9: #969696;
@color_10: #333;
@color_11: #666;
@color_12: #656565;
@color_13: #353535;
@color_14: #888;
@color_15: #F58723;
@color_16: #454444;
@font_family_1: 'Open Sans', sans-serif;
@font_family_2: 'Montserrat', sans-serif;
@font_family_3: 'Glyphicons Halflings';
@background_color_1: #fff;
@background_color_2: #f5f5f5;
@background_color_3: #ebebeb;
@background_color_4: #00bcd4;
@background_color_5: rgb( 199, 199, 199 );
@background_color_6: rgba(0,0,0, .1);
@background_color_7: #07a7bb;
@background_color_8: #6D6D6D;
@background_color_9: #808080;
@background_color_10: rgba(50,50,50, .01);
@background_color_11: #f9f9f9;
@background_color_12: #dfdfdf;
@background_color_13: #353535;
@border_color_1: transparent;
@border_color_2: #ddd;

/*Color

Blue: #00bcd4;
Body Color: #414141;
icon color: #a1a1a1;


*/
/*Top Navbar*/
/*Top Navbar*/
/*Header*/
/*Header*/
/* About */
/*Awards*/
/* About */
/*Awards*/
/*Awards*/
/*Services*/
/*Services*/
/*Featured Work*/
/*Featured Work*/
/*Testimonials*/
/*Testimonials*/
/*Blog*/
/*Blog*/
/*Footer*/
/*Footer*/
/*Pricing*/
/* green panel */
/* grey price */
/* white price */
/*Pricing*/
/*Blog*/
/* ===============================
15. Aside, sidebar
================================ */
/*Blog*/
/* --- pagination --- */
/*============================
 Forms
============================= */
/* --- contact form  ---- */
/* --- comment form ---- */
/* --- search form --- */
/* --- Map --- */
/*FAQ*/
/*FAQ*/
body {
	font-size: 14px;
	font-weight: 300;
	line-height: 30px;
	font-family: @font_family_1;
	background-color: @background_color_1;
	margin-top: 50px;
	color: @color_1;
}
ul {
	margin: 0;
	padding: 0;
}
li {
	list-style: disc;
}
a {
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}
img {
	max-width: 100%;
}
h1 {
	font-weight: bold;
	text-rendering: optimizelegiblity;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
	font-family: @font_family_2;
}
h2 {
	font-weight: bold;
	text-rendering: optimizelegiblity;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
	font-family: @font_family_2;
}
h3 {
	font-weight: bold;
	text-rendering: optimizelegiblity;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
	font-family: @font_family_2;
	font-size: 30px;
}
h4 {
	font-weight: bold;
	text-rendering: optimizelegiblity;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
	font-family: @font_family_2;
}
h5 {
	font-weight: bold;
	text-rendering: optimizelegiblity;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
	font-family: @font_family_2;
}
h6 {
	font-weight: bold;
	text-rendering: optimizelegiblity;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
	font-family: @font_family_2;
}
p {
	margin: 0;
	padding: 0;
	line-height: 26px;
	font-size: 14px;
	margin: 8px 0;
}
.copy-text-cmp {
	color: @color_2;
}
.navbar-inverse {
	background-color: @background_color_2;
	border-color: @border_color_1;
	padding: 10px 0;
}
ul.navbar-nav {
	li {
		a {
			font-family: @font_family_2;
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 400;
			color: @color_1 !important;
		}
	}
}
.is-sticky {
	.main-menu {
		z-index: 9999999 !important;
		position: absolute;
		background: #000;
		.navbar-inverse {
			background: #000;
			transition: 0.4s;
			padding: 15px 0;
		}
		ul.navbar-nav {
			li {
				a {
					color: @color_3 !important;
				}
			}
		}
	}
}
.carousel-caption {
	top: 35%;
	color: @color_2;
	h1 {
		font-size: 40px;
		line-height: auto;
		text-transform: uppercase;
	}
}
.slider-btn {
	margin-top: 21px;
}
.indicatior2 {
	top: 90% !important;
}
.btn.btn-learn {
	background: #00bcd4 none repeat scroll 0 0;
	border-radius: 50px;
	color: @color_4;
	font-weight: 500;
	padding: 15px 30px;
	text-align: center;
	text-transform: uppercase;
	font-family: @font_family_2;
}
.site-padding {
	padding: 70px 0;
}
.btn.btn-read-more {
	background: #00bcd4 none repeat scroll 0 0;
	border-radius: 50px;
	-moz-border-radius: 50px;
	-webkit-border-radius: 50px;
	color: @color_4;
	font-size: 16px;
	font-weight: 600;
	padding: 9px 21px;
}
#about {
	background: #f5f5f5;
}
.about-text {
	text-align: left;
	h6 {
		font-size: 12px;
	}
}
#awards {
	background: #f5f5f5;
}
.award-win {
	background: #00bcd4;
	padding: 60px 10px;
	color: @color_4;
	text-align: center;
}
.trophy {
	i {
		font-size: 50px;
		color: @color_4;
	}
}
.progress-bar-custom {
	margin-bottom: 60px;
	h5 {
		font-size: 16px;
		font-weight: normal;
		text-transform: uppercase;
		margin-bottom: 15px;
	}
}
.progress {
	background-color: @background_color_3;
	height: 29px;
	overflow: hidden;
	border: 1px solid #c4c4c4;
}
.progress-bar {
	background-color: @background_color_4;
	line-height: 27px;
}
.title {
	h3 {
		text-align: center;
		span {
			color: @color_2;
		}
	}
	margin-bottom: 50px;
}
#features {
	background: #f5f5f5;
}
.feature-icon {
	i {
		font-size: 50px;
		color: @color_5;
	}
}
.feature-text {
	h4 {
		font-size: 16px;
		text-transform: uppercase;
	}
	p {
		margin: 5px 0;
	}
	a {
		font-size: 13px;
		color: @color_2;
		text-transform: uppercase;
		font-weight: 500;
		font-family: @font_family_2;
	}
}
.single-feature {
	margin-bottom: 30px;
}
.featured-list {
	width: 100%;
	margin: auto;
	#grid {
		width: 100%;
		.thumb {
			float: left;
			height: auto;
			margin: 0;
			width: 25%;
		}
	}
}
.protfolio-padding {
	padding-top: 70px;
}
.thumb {
	line-height: 0;
	overflow: hidden;
	position: relative;
	a {
		display: inline-block;
		float: left;
		height: 100%;
		line-height: 0;
		text-decoration: none;
		width: 100%;
		img {
			display: block;
			position: relative;
			width: inherit;
		}
	}
	img {
		display: block;
		position: relative;
		width: inherit;
	}
	&:hover {
		.thumb-rollover {
			display: block;
			transition: 0.2s;
		}
	}
}
.thumb-rollover {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	background: #00bcd4;
	opacity: 0.9;
	text-align: center;
	color: @color_4;
	display: none;
	transition: 0.2s;
}
.project-title {
	text-align: center;
	margin: auto;
	width: auto;
	margin-top: 30%;
	transition: 0.2s;
	h4 {
		font-size: 18px;
		line-height: 30px;
		text-transform: uppercase;
	}
	h5 {
		font-size: 12px;
		text-transform: uppercase;
	}
}
.testimonials-padding {
	padding-bottom: 70px;
}
#testimonials {
	background: #f5f5f5 none repeat scroll 0 0;
	min-height: 290px;
	text-rendering: optimizelegiblity;
	-webkit-font-smoothing: antialiased;
	padding: 70px 0;
}
#fawesome-carousel-two {
	min-height: 170px;
}
.text {
	background: #f4f4f4;
	text-align: left;
	padding: 25px;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	p {
		color: @color_1;
	}
}
.profile-image {
	margin: 0 auto;
	text-align: center;
	margin-top: 30px;
	img {
		height: 100px;
		width: 100px;
		border-radius: 500px;
		-moz-border-radius: 500px;
		-webkit-border-radius: 500px;
		margin-bottom: 10px;
	}
	h4 {
		font-size: 18px;
		color: @color_2;
		text-transform: uppercase;
		line-height: 22px;
		font-weight: normal;
	}
	p {
		font-size: 14px;
		font-weight: normal;
		margin: 0;
	}
}
.carousel-indicators {
	top: 105%;
	.active {
		background-color: @background_color_5;
		height: 12px;
		margin: 0;
		width: 12px;
	}
	li {
		background-color: @background_color_5;
		border: 1px solid #fff;
		border-radius: 10px;
		cursor: pointer;
		display: inline-block;
		height: 10px;
		margin: 1px;
		text-indent: -999px;
		width: 10px;
	}
}
#blog {
	background: #f7f7f7;
}
.single-blog {
	img {
		margin-bottom: 15px;
	}
	margin-bottom: 15px;
	margin-bottom: 40px;
	a {
		text-decoration: none;
		color: @color_1;
	}
	h3 {
		font-size: 28px;
		font-weight: bold;
		margin-bottom: 20px;
		line-height: 35px;
	}
}
.read-more {
	a {
		font-size: 13px;
		font-weight: normal;
		text-transform: uppercase;
		color: @color_2;
		font-family: @font_family_2;
	}
}
footer {
	background: #414141;
	padding: 60px 0;
	color: @color_6;
}
.single-footer {
	h4 {
		font-size: 16px;
		font-weight: 400;
		text-transform: uppercase;
		margin-bottom: 10px;
	}
}
ul.projects {
	li {
		display: inline-block;
		margin-bottom: 4px;
		width: 32%;
	}
}
.copyright {
	background: #353535;
	padding: 10px 0;
	color: @color_4;
}
.footer-menu {
	ul {
		li {
			display: inline-block;
			padding-left: 5px;
			a {
				font-size: 14px;
				font-weight: normal;
				color: @color_7;
				font-family: @font_family_2;
				text-transform: uppercase;
			}
		}
	}
}
.social {
	ul {
		li {
			display: inline-block;
			padding-left: 5px;
			a {
				i {
					height: 35px;
					width: 35px;
					background: #757575;
					text-align: center;
					color: @color_8;
					line-height: 35px;
					font-size: 18px;
					border-radius: 500px;
				}
			}
		}
	}
	margin-top: 4px;
}
.footer-menu.pull-right {
	margin-top: 7px;
}
.my-form {
	height: 45px;
	border-radius: 0px;
	background: #d4d4d4;
	color: @color_9;
	font-family: @font_family_2;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 500;
}
.btn.btn-subscribe {
	background: #00bcd4 none repeat scroll 0 0;
	border-radius: 0;
	color: @color_4;
	font-family: @font_family_2;
	font-weight: 500;
	height: 45px;
	text-align: center;
	text-transform: uppercase;
	width: 100%;
}
.pricing {
	padding: 70px 0;
}
.panel.price {
	border-radius: 0px;
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	>.panel-heading {
		border-radius: 0px;
		-moz-transition: all .3s ease;
		-o-transition: all .3s ease;
		-webkit-transition: all .3s ease;
		box-shadow: 0px 5px 0px rgba(50,50,50, .2) inset;
	}
	&:hover {
		box-shadow: 0px 0px 30px rgba(0,0,0, .2);
		>.panel-heading {
			box-shadow: 0px 0px 30px rgba(0,0,0, .2) inset;
		}
	}
	.list-group-item {
		&:last-child {
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
		}
		&:first-child {
			border-top-right-radius: 0px;
			border-top-left-radius: 0px;
		}
	}
	.btn {
		box-shadow: 0 -1px 0px rgba(50,50,50, .2) inset;
		border: 0px;
	}
}
.price {
	.list-group-item {
		border-bottom-: 1px solid rgba(250,250,250, .5);
	}
	.panel-footer {
		color: @color_4;
		border-bottom: 0px;
		background-color: @background_color_6;
		box-shadow: 0px 3px 0px rgba(0,0,0, .3);
	}
	&:hover.panel-white {
		>.panel-body {
			.lead {
				color: @color_10;
			}
		}
	}
}
.price.panel-green {
	>.panel-heading {
		color: @color_4;
		background-color: @background_color_7;
	}
	>.panel-body {
		color: @color_4;
		background-color: @background_color_4;
	}
	.list-group-item {
		color: @color_10;
		font-weight: 600;
	}
}
.price.panel-grey {
	>.panel-heading {
		color: @color_4;
		background-color: @background_color_8;
	}
	>.panel-body {
		color: @color_4;
		background-color: @background_color_9;
	}
	.list-group-item {
		color: @color_10;
		background-color: @background_color_10;
		font-weight: 600;
	}
}
.price.panel-white {
	>.panel-heading {
		color: @color_10;
		background-color: @background_color_11;
	}
	>.panel-body {
		color: @color_4;
		background-color: @background_color_12;
		.lead {
			color: @color_11;
		}
	}
	.list-group-item {
		color: @color_10;
		background-color: @background_color_10;
		font-weight: 600;
	}
}
.panel.panel-white.price {
	&:hover {
		>.panel-heading {
			box-shadow: 0px 0px 30px rgba(0,0,0, .05) inset;
		}
	}
}
.btn.btn-lg.btn-block.btn-success {
	background: #00bcd4 none repeat scroll 0 0;
	border: 1px solid #00bcd4;
	color: @color_4;
	font-size: 16px;
	font-weight: 600;
}
.btn.btn-lg.btn-block.btn-primary {
	background: #6d6d6d none repeat scroll 0 0;
	border: 1px solid #6d6d6d;
	color: @color_4;
	font-size: 16px;
	font-weight: 600;
}
.blog-single {
	margin-top: 140px;
	margin-bottom: 70px;
}
.blog-info {
	padding: 10px 5px;
	border: 1px solid #e1e1e1;
	overflow: hidden;
	ul {
		float: left;
		li {
			display: inline-block;
			padding: 0 10px;
			border-right: 1px solid #e1e1e1;
			a {
				font-size: 12px;
				color: @color_1;
				text-transform: none;
			}
		}
	}
}
aside {
	position: relative;
	margin-bottom: 40px;
	.widget {
		margin-bottom: 40px;
	}
	.widgetheading {
		position: relative;
		border-bottom: #e9e9e9 1px solid;
		padding-bottom: 9px;
		border-bottom: 1px solid #e9e9e9;
		margin-bottom: 17px;
		padding-bottom: 9px;
		position: relative;
	}
}
.sidebar-nav {
	float: left;
	width: 100%;
}
.right-sidebar {
	border-left: 1px solid #f5f5f5;
	padding: 0 0 0 30px;
	-webkit-box-shadow: inset 1px 0 0 0 rgba(0,0,0,.01);
	box-shadow: inset 1px 0 0 0 rgba(0,0,0,.01);
}
.left-sidebar {
	border-right: 1px solid #f5f5f5;
	padding: 0 30px 0 0;
	-webkit-box-shadow: inset 1px 0 0 0 rgba(0,0,0,.01);
	box-shadow: inset 1px 0 0 0 rgba(0,0,0,.01);
	.widget {
		h3 {
			padding-right: 20px;
		}
		.widget-content {
			padding-right: 20px;
		}
	}
}
ul.cat {
	margin: 0;
	padding: 0;
	list-style: none;
	list-style: outside none none;
	margin: 15px 0 0;
	padding: 0;
	li {
		list-style: outside none none;
		margin: 0 0 8px;
		a {
			color: @color_12;
		}
	}
}
ul.recent {
	margin: 0;
	padding: 0;
	list-style: none;
	list-style: outside none none;
	margin: 15px 0 0;
	padding: 0;
	li {
		list-style: outside none none;
		margin: 0 0 8px;
		h6 {
			margin: 0 0 10px 0;
			a {
				color: @color_13;
				font-size: 16px;
				font-weight: 600;
			}
		}
	}
}
ul.folio-detail {
	margin: 0;
	padding: 0;
	list-style: none;
	list-style: outside none none;
	margin: 15px 0 0;
	padding: 0;
	li {
		list-style: outside none none;
		margin: 0 0 8px;
		a {
			color: @color_12;
		}
		label {
			display: inline;
			cursor: default;
			color: @color_13;
			font-weight: bold;
		}
	}
}
ul.contact-info {
	margin: 0;
	padding: 0;
	list-style: none;
	list-style: outside none none;
	margin: 15px 0 0;
	padding: 0;
	li {
		list-style: outside none none;
		margin: 0 0 8px;
	}
}
.widget {
	ul.recent {
		li {
			margin-bottom: 20px;
		}
	}
	ul.tags {
		list-style: none;
		margin: 0;
		margin-left: 0;
		padding-left: 0;
		li {
			margin: 0 5px 15px 0;
			display: inline-block;
			a {
				background: #e6e6e6;
				color: @color_10;
				padding: 5px 10px;
				&:hover {
					text-decoration: none;
					color: @color_4;
					background: #00bcd4 none repeat scroll 0 0;
					color: @color_8;
					padding: 5px 10px;
				}
			}
		}
	}
}
.blog-thumb-image {
	margin-right: 15px;
}
#pagination {
	a {
		display: block;
		float: left;
		margin: 0 7px 0 0;
		padding: 7px 10px 6px 10px;
		font-size: 12px;
		line-height: 12px;
		color: @color_14;
		font-weight: 600;
		&:hover {
			color: @color_4;
			text-decoration: none;
			color: @color_4;
			background: #00bcd4;
		}
	}
	span {
		display: block;
		float: left;
		margin: 0 7px 0 0;
		padding: 7px 10px 6px 10px;
		font-size: 12px;
		line-height: 12px;
		color: @color_14;
		font-weight: 600;
	}
	span.current {
		background: #333;
		color: @color_4;
		font-weight: bold;
	}
}
form#contactform {
	input[type="text"] {
		width: 100%;
		border: 1px solid #c6c6c6;
		min-height: 40px;
		padding-left: 20px;
		font-size: 13px;
		padding-right: 20px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		margin-bottom: 20px;
	}
	textarea {
		border: 1px solid #c6c6c6;
		width: 100%;
		padding-left: 20px;
		padding-top: 10px;
		font-size: 13px;
		padding-right: 20px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	.validation {
		font-size: 11px;
	}
}
#sendmessage {
	border: 1px solid #e6e6e6;
	background: #f6f6f6;
	display: none;
	text-align: center;
	padding: 15px 12px 15px 65px;
	margin: 10px 0;
	font-weight: 600;
	margin-bottom: 30px;
}
#sendmessage.show {
	display: block;
}
.show {
	display: block;
}
form#commentform {
	input[type="text"] {
		width: 100%;
		min-height: 40px;
		padding-left: 20px;
		font-size: 13px;
		padding-right: 20px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-border-radius: 2px 2px 2px 2px;
		-moz-border-radius: 2px 2px 2px 2px;
		border-radius: 2px 2px 2px 2px;
	}
	textarea {
		width: 100%;
		padding-left: 20px;
		padding-top: 10px;
		font-size: 13px;
		padding-right: 20px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-border-radius: 2px 2px 2px 2px;
		-moz-border-radius: 2px 2px 2px 2px;
		border-radius: 2px 2px 2px 2px;
	}
}
.contact {
	h4 {
		margin-bottom: 20px;
	}
	padding-bottom: 70px;
}
.btn.btn-theme.margintop10 {
	background: #00bcd4;
	color: @color_4;
}
.search {
	float: right;
	margin: 35px 0 0;
	padding-bottom: 0;
}
#inner-headline {
	form.input-append {
		margin: 0;
		padding: 0;
	}
}
.map {
	position: relative;
	margin-top: -50px;
	margin-bottom: 40px;
	iframe {
		width: 100%;
		height: 450px;
		border: none;
	}
}
.map-grid {
	iframe {
		width: 100%;
		height: 350px;
		border: none;
		margin: 0 0 -5px 0;
		padding: 0;
	}
}
#faq {
	padding: 70px 0;
	background: #F7F7F7;
}
.faqHeader {
	font-size: 27px;
	margin: 20px;
}
.panel-heading {
	[data-toggle="collapse"] {
		&:after {
			font-family: @font_family_3;
			content: "\e072";
			float: right;
			color: @color_15;
			font-size: 18px;
			line-height: 22px;
			-webkit-transform: rotate(-90deg);
			-moz-transform: rotate(-90deg);
			-ms-transform: rotate(-90deg);
			-o-transform: rotate(-90deg);
			transform: rotate(-90deg);
		}
	}
	[data-toggle="collapse"].collapsed {
		&:after {
			-webkit-transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			-o-transform: rotate(90deg);
			transform: rotate(90deg);
			color: @color_16;
		}
	}
}
h4.panel-title {
	font-weight: normal;
	font-size: 15px;
	font-family: @font_family_1;
}
.panel {
	background-color: @background_color_1;
	border: 1px solid #ddd;
	border-radius: 0px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	margin-bottom: 20px;
}
.panel-default {
	>.panel-heading {
		background-color: @background_color_1;
		border-color: @border_color_2;
		color: @color_1;
	}
}
.navbar-toggle {
	background-color: @background_color_13;
}
#contact-us {
	background: #f5f5f5;
	padding: 70px 0;
}
#feature-work {
	background: #f5f5f5;
}
#careers {
	background: #f5f5f5;
}
#partners {
	background: #f5f5f5;
}
